<template>
  <form
    class="targets-form flow"
  >
    <CreateAnalysisFields
      @field-selected="handleFieldSelection"
      @validity-changed="(value) => handleValidityChanged(value, 'analysisFields')"
    />

    <CreateProperty
      :disabled="!isAnalysisFieldSelected"
      :selected-properties="selectedProperties"
      :property-options="propertyOptions"
      @property-selected="handlePropertySelection"
      @validity-changed="(value) => handleValidityChanged(value, 'property')"
    />

    <CreateUsers
      disabled
      :selected-user="selectedUser"
      @user-selected="handleUserSelection"
    />

    <CreateData
      :disabled="!isPropertyFieldSelected"
      @interval-selected="handleIntervalSelection"
      @expiry-year-selected="handleExpiryYearSelection"
      @validity-changed="(value) => handleValidityChanged(value, 'date')"
    />

    <CreateMeasurement
      :disabled="!isDateFieldSelected"
      @measurement-selected="handleMeasurementSelection"
      @validity-changed="(value) => handleValidityChanged(value, 'measurement')"
    />

    <CreateTargetAmount
      :disabled="!isMeasurementFieldSelected"
      @target-amount="handleTargetAmount"
      @validity-changed="(value) => handleValidityChanged(value, 'targetAmount')"
    />
    <CreateFormActions
      :form-data="formData"
      :is-valid="isValid"
      @target-cancelled="handleResetFormData"
      @target-created="handleResetFormData"
    />
  </form>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import useTargets from '@/intelligence/composables/useTargets';
import CreateAnalysisFields from '@/admin/components/Targets/CreateAnalysisFields.vue';
import CreateProperty from '@/admin/components/Targets/CreateProperty.vue';
import CreateUsers from '@/admin/components/Targets/CreateUsers.vue';
import CreateData from '@/admin/components/Targets/CreateDate.vue';
import CreateMeasurement from '@/admin/components/Targets/CreateMeasurement.vue';
import CreateTargetAmount from '@/admin/components/Targets/CreateTargetAmount.vue';
import CreateFormActions from '@/admin/components/Targets/CreateFormActions.vue';

const store = useStore();
const validity = ref({
  analysisFields: false,
  property: false,
  date: false,
  measurement: false,
  targetAmount: false,
});

const {
  formData,
  isAnalysisFieldSelected,
  isPropertyFieldSelected,
  isDateFieldSelected,
  isMeasurementFieldSelected,
  handleFieldSelection,
  handlePropertySelection,
  handleUserSelection,
  handleIntervalSelection,
  handleExpiryYearSelection,
  handleMeasurementSelection,
  handleTargetAmount,
  propertyOptions,
  selectedProperties,
  selectedUser,
  resetFormData
} = useTargets({ store });

const isValid = computed(() => {
  // Check the values of the items in the validity object
  return Object.values(validity.value).every(value => value);
});

function handleValidityChanged(value, component) {
  validity.value[component] = value;
}

function handleResetFormData() {
  resetFormData(validity);
}
</script>

<style lang="scss" scoped>
  // Overrides
  :deep(.form-group .input-container .svg-container.icon.right,
  .form-group .input-container button.icon.right) {
    z-index: 2;
  }

  :deep(select):not(.expiry select),
  :deep(input[type="text"]),
  :deep(input[type="number"]) {
    font-weight: normal;
  }

  .button-wrapper {
    display: flex;
    gap: var(--spacing-1);
    justify-content: center;
  }


  input[type="checkbox"] {
    box-shadow: none;
  }

  .targets-form {
    width: 100%;
  }
</style>