import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { FIELDS_LOOKUP } from '@/admin/store/actionType';
import { GET_FIELDS } from '@/shared/store/actionType';

export default function useAdminUsers({ store = useStore() } = {}) {
  const restrictions = computed(() => store.state.admin.users.currentlyEditing.restrictions);
  const rulesets = ref([{ mappingId: 1, type: '', values: {} }]);
  const rulesetCount = ref(1); // default to this as starts with 1 ruleset


  function countEntities(data) {
    const counts = {
      analysis: 0,
      crm: 0
    };
    
    function processItem(item) {
      // If item is an array, process each element
      if (Array.isArray(item)) {
        item.forEach(subItem => processItem(subItem));
        return;
      }
      
      // Skip clause objects
      if (item.clause) return;
      
      // Count if item has a type (either 'analysis' or 'crm')
      if (item.type && (item.type === 'analysis' || item.type === 'crm')) {
        counts[item.type]++;
      }
    }
    
    processItem(data);
    
    return counts;
  }
    
  // Get a count of the total number of entities by type
  const analysisEntityCount = computed(() => countEntities(restrictions.value).analysis);
  const crmEntityCount = computed(() => countEntities(restrictions.value).crm);
  
  // Split rulesets by type for later calculations
  const analysisRulesets = computed(() => rulesets.value.filter(rule => rule.type === 'analysis'));
  const crmRulesets = computed(() => rulesets.value.filter(rule => rule.type === 'crm'));
  
  // Get the total number of entities and rulesets
  const totalAnalysisEntities = computed(() => analysisEntityCount.value + analysisRulesets.value.length);
  const totalCrmEntities = computed(() => crmEntityCount.value + crmRulesets.value.length);

  // Maximum limits for entities
  const isTotalAnalysisEntityMax = computed(() => totalAnalysisEntities.value >= 2);
  const isTotalCrmEntityMax = computed(() => totalCrmEntities.value >= 5);

  const isCRMEntityMax = computed(() => crmEntityCount.value >= 5);
  const isAnalysisEntityMax = computed(() => analysisEntityCount.value >= 2);

  const isShowAddRules = computed(() => !isCRMEntityMax.value || !isAnalysisEntityMax.value);
  
  const getFields = (params) => store.dispatch(`admin/users/${GET_FIELDS}`, params);
  const fieldsLookup = (params) => store.dispatch(`admin/users/${FIELDS_LOOKUP}`, params);

  return {
    getFields,
    fieldsLookup,
    analysisEntityCount,
    analysisRulesets,
    crmEntityCount,
    crmRulesets,
    isAnalysisEntityMax,
    isCRMEntityMax,
    isShowAddRules,
    isTotalAnalysisEntityMax,
    isTotalCrmEntityMax,
    restrictions,
    rulesetCount,
    rulesets,
    totalAnalysisEntities,
    totalCrmEntities
  };
}