export const getResponseParameters = () => type => {
  switch (type) {
  case 'get':
    return `
      header
      targets {
        id
        last_update
        analysis_fields {
          analysis_field
          area
          title
          value
          type
        }
        target_period {
          interval
          expires
        }
        target_value {
          id
          measurement {
            metric
            unit
          }
          value
        }
        attained {
          attained_value
          progress
        }
      }
    `;
  case 'add':
  case 'update':
  case 'delete':
    return `
      id
      last_update
      analysis_fields {
        analysis_field
        area
        title
        value
        type
      }
      target_period {
        interval
        expires
      }
      target_value {
        id
        measurement {
          metric
          unit
        }
        value
      }
      attained {
        attained_value
        progress
      }
    `;
  case 'deleteTargetsByBubbleId':
    return '';
  }
};