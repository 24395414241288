<template>
  <DropDown
    id="property"
    :is-dropdown-visible="dropdownVisibility.property"
    :show-footer="showFooter"
    :disabled="props.disabled"
    @trigger-click="toggleDropdown"
    @update:is-dropdown-visible="updateDropdownVisibility('property', $event)"
  >
    <template #label>
      {{ t('Property') }}
    </template>

    <template #trigger>
      <div
        id="property"
        class="dropdown-trigger"
        :value="selectedPropertiesText"
      >
        {{ selectedPropertiesText || t('Select property') }}
      </div>
      <IconBase
        class="chevron"
        width="32"
        height="32"
        icon-name="chevron-down"
        icon-color="var(--colour-panel-g-64)"
      />
    </template>

    <template #content>
      <div class="p-2 flow">
        <CustomCheckbox
          v-for="value in propertyOptions"
          :key="value.id"
          :label="`${ value.description } - ${ value.code }`"
          :value="isChecked(value)"
          @input="updateTempSelectedProperties(value)"
        />
      </div>
    </template>

    <template
      v-if="showFooter"
      #footer
    >
      <CustomButton
        purpose="action"
        small
        @click="applyPropertySelection"
      >
        {{ t('Apply') }}
      </CustomButton>
    </template>
  </DropDown>
</template>

<script setup>
import { ref, computed, defineEmits, watch } from 'vue';
import { t } from '@sales-i/utils';
import { CustomButton, CustomCheckbox, IconBase } from '@sales-i/dsv3';
import DropDown from '@/admin/components/Targets/DropDown.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  propertyOptions: {
    type: Array,
    default: () => []
  },
  selectedProperties: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['property-selected', 'validity-changed']);

const dropdownVisibility = ref({ property: false });
const tempSelectedProperties = ref([]);

const showFooter = computed(() => {
  // Only show the apply button if the data in the tempSelectedProperties and selectedProperties are different.
  return JSON.stringify(tempSelectedProperties.value) !== JSON.stringify(props.selectedProperties);
});

// Computed property to generate the string representation of selected users
const selectedPropertiesText = computed(() => {
  const selectedProperties = props.selectedProperties;
  if (selectedProperties.length > 1) {
    return `${selectedProperties[0].description} - ${selectedProperties[0].code} (+${selectedProperties.length - 1})`;
  } else if (selectedProperties.length === 1) {
    return `${selectedProperties[0].description} - ${selectedProperties[0].code}`;
  } else {
    return '';
  }
});

const isValid = computed(() => {
  return props.selectedProperties.length > 0;
});
  
// If the user selects a different analysis field, reset the tempSelectedProperties
watch(() => props.selectedProperties, (newValue) => {
  if (newValue.length === 0) {
    tempSelectedProperties.value = [];
  }
  emit('validity-changed', isValid.value);
});

const isChecked = (value) => {
  return tempSelectedProperties.value.some(item => item.id === value.id);
};

const updateTempSelectedProperties = (value) => {
  const index = tempSelectedProperties.value.findIndex(item => item.id === value.id);

  if (index === -1) {
    tempSelectedProperties.value.push(value);
  } else {
    tempSelectedProperties.value.splice(index, 1);
  }
};

function applyPropertySelection() {
  emit('property-selected', [...tempSelectedProperties.value]);
  dropdownVisibility.value.property = false;
  
  // Adjust focus to the dropdown trigger
  document.getElementById('property').focus();
}

const toggleDropdown = (dropdownId) => {
  dropdownVisibility.value[dropdownId] = !dropdownVisibility.value[dropdownId];
  for (const key in dropdownVisibility.value) {
    if (key !== dropdownId) {
      dropdownVisibility.value[key] = false;
    }
  }
};

const updateDropdownVisibility = (dropdownId, isVisible) => {
  dropdownVisibility.value[dropdownId] = isVisible;
};
</script>

<style lang="scss" scoped>
  .property {
    position: relative;
  }

  .dropdown-trigger {
    background-color: var(--colour-utility-white);
    border-radius: var(--border-radius-half);
    box-shadow: 0px 0px 0px 1px var(--border-utility-base);
    line-height: 1.75;
    outline: 2px solid transparent;
    padding-block: var(--spacing-1);
    padding-inline: var(--spacing-2) var(--spacing-6);
    text-align: left;
    white-space: nowrap;
    width: 100%;
  }

  .chevron {
    position: absolute;
    right: var(--spacing-1);
    top: var(--spacing-1);
  }
  
  :deep(.dropdown-list) {
    min-width: 16rem;
  }
</style>