export const getRequestParameters = () => ([reportType, params]) => {
  const {
    selectedAnalysisField = {},
    selectedProperties = [],
    selectedDate = {},
    selectedMeasurement,
    targetAmount,

    year = new Date().getFullYear(),
    offset = 0,
    limit = 20,
    sortHeader = 'name',
    sortOption = 'asc',
    targetId,
    bubbleId,
  } = params;

  const analysis_field = selectedAnalysisField?.fieldName ?? '';
  const area = selectedAnalysisField?.entity ?? '';
  const title = selectedAnalysisField?.fieldName ?? '';
  const propertyValues = selectedProperties.map(property => property.code);
  const type = 'analysis_field';
  const interval = selectedDate?.interval ?? '';
  const expires = selectedDate?.expires ?? '';
  const measurement = selectedMeasurement ?? '';
  const measurementValue = targetAmount ?? '';

  switch (reportType) {
  case 'get':
    return `
      filter: {
        year: ${year}, 
        interval: ${interval}
      }
      pagination: {
          offset: ${offset}, 
          limit: ${limit}
      }
      sort: "${sortHeader}:${sortOption}"
    `;
  case 'add':
  case 'update':
    return `
      input: {
        analysis_fields: {
          analysis_field: "${analysis_field}"
          area: "${area}"
          title: "${title}"
          value: ${JSON.stringify(propertyValues)}
          type: "${type}"
        }
        target_period: {
          interval: ${interval}
          expires: "${expires}"
      }
        target_value: {
          measurement: "${measurement}"
          value: ${measurementValue}
        }
      }
    `;
  case 'delete':
    return `id: ${targetId}`;
  case 'deleteTargetsByBubbleId':
    return `bubbleId: ${bubbleId}`;
  }
};
