
import { computed, ref } from 'vue';
import { CLEAR_CURRENT_TARGET, ENABLE_TARGETS, FETCH_REPORT_DATA, SAVE_CURRENT_DIMENSIONS, SAVE_CURRENT_VALUES, SAVE_WHOLE_DIM, TOGGLE_TARGETS_SWITCH, } from '@/intelligence/store/actionType';
import { useStore } from 'vuex';
import useAdminUsers from '@/admin/composables/useAdminUsers';
import { GET_BY_ID } from '@/shared/store/actionType';

const initialFormData = {
  selectedAnalysisField: '',
  selectedProperties: [],
  selectedUsers: [],
  selectedDate: {
    interval: undefined,
    expires: undefined
  },
  selectedMeasurement: undefined,
  targetAmount: ''
};

export default function useTargets({ store = useStore() } = {}) {
  const { fieldsLookup } = useAdminUsers({ store });
  const propertyOptions = ref([]);
  const selectedProperties = ref([]);
  const selectedUsers = ref([]);

  const isAnalysisFieldSelected = computed(() => formData.value.selectedAnalysisField !== '');
  const isPropertyFieldSelected = computed(() => formData.value.selectedProperties.length > 0);
  const isUserFieldSelected = computed(() => formData.value.selectedUsers.length > 0);
  const isDateFieldSelected = computed(() => formData.value.selectedDate.interval !== undefined && formData.value.selectedDate.expires !== undefined);
  const isMeasurementFieldSelected = computed(() => formData.value.selectedMeasurement !== undefined);
  
  const isTargetsSwitchActive = computed(() => store.state.intelligence.targets.isTargetsSwitchActive);
  const areTargetsEnabled = computed(() => store.state.intelligence.targets.areTargetsEnabled);  
  const currentTargetValues = computed(() => store.state.intelligence.targets.currentTargetValues);
  const valid = computed(() => currentTargetValues.value.length && currentTargetValues.value.every(item => item.values.length || item.checkedAll));
  const measurements = computed(() => store.state.intelligence.targets.measurementsData);
  const targetsData = computed(() => store.state.intelligence.targets.targetsData || { targets: [] });
  
  const getTarget = id => store.dispatch(`intelligence/targets/${GET_BY_ID}`, id);
  const toggleTargetsSwitch = (params) => store.dispatch(`intelligence/targets/${TOGGLE_TARGETS_SWITCH}`, params);
  const enableTargets = value => store.dispatch(`intelligence/targets/${ENABLE_TARGETS}`, value);
  const clearCurrentTarget = () => store.dispatch(`intelligence/targets/${CLEAR_CURRENT_TARGET}`);
  const queryTargetData = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
  const saveCurrentValues = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_VALUES}`, payload);
  const saveWholeDim = payload => store.dispatch(`intelligence/targets/${SAVE_WHOLE_DIM}`, payload);
  const saveCurrentDims = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_DIMENSIONS}`, payload);
  const deleteTarget = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);

  // Create a local array of the data the user has entered so far
  const formData = ref({ ...initialFormData });

  // Get the field values from the store
  const fetchFieldsValue = async (entity, fieldId) => {
    await fieldsLookup({
      entity: entity,
      id: fieldId
    });
    
    const fieldsDataValue = store.state.admin.users.fields.data[entity] || [];
    
    // Update propertyOptions with the fetched values
    propertyOptions.value = fieldsDataValue;
  };

  const handleFieldSelection = async (value) => {
    if (value) {
      const { entity, fieldId, fieldName } = value;
  
      // Set the option in the select field to the default value
      selectedProperties.value = [];

      // Clear any previously select options in the selectedProperties array
      formData.value.selectedProperties = [];
      formData.value.selectedAnalysisField = value;
  
      // Fetch field values for the selected field
      await fetchFieldsValue(entity, fieldId, fieldName);
    }
  };

  const handlePropertySelection = (value) => {
    selectedProperties.value = value;
    formData.value.selectedProperties = value;
  };

  const handleUserSelection = (value) => {
    selectedUsers.value = value;
    formData.value.selectedUsers = value;
  };
  
  const handleIntervalSelection = (value) => {
    formData.value.selectedDate.interval = value;
  };
  
  const handleExpiryYearSelection = (event) => {
    formData.value.selectedDate.expires = event;
  };
    
  const handleMeasurementSelection = (event) => {
    formData.value.selectedMeasurement = event;
  };
  
  const handleTargetAmount = (event) => {
    formData.value.targetAmount = event;
  };

  const resetFormData = (validity) => {
    // Reset the formData object to its initial state
    formData.value = { ...initialFormData };
    
    // Reset propertyOptions and selectedProperties
    propertyOptions.value = [];
    selectedProperties.value = [];

    if (validity) {
      validity.value = {
        analysisFields: false,
        date: false,
        measurement: false,
        property: false,
        targetAmount: false,
      };
    }

    return formData.value;
  };

  // helpers
  const metricOptions = computed(() => measurements.value.length ? measurements.value.map(el => (
    { unit: el.unit, text: el.metric, value: el.id }
  )) : []);

  return {
    targetsData,
    currentTargetValues,
    isTargetsSwitchActive,
    areTargetsEnabled,
    valid,
    measurements,
    metricOptions,
    propertyOptions,
    getTarget,
    toggleTargetsSwitch,
    enableTargets,
    clearCurrentTarget,
    queryTargetData,
    saveCurrentValues,
    saveWholeDim,
    saveCurrentDims,
    deleteTarget,
    handleFieldSelection,
    formData,
    selectedProperties,
    fetchFieldsValue,
    isAnalysisFieldSelected,
    isPropertyFieldSelected,
    isUserFieldSelected,
    isDateFieldSelected,
    isMeasurementFieldSelected,
    handlePropertySelection,
    handleUserSelection,
    handleIntervalSelection,
    handleExpiryYearSelection,
    handleMeasurementSelection,
    handleTargetAmount,
    resetFormData
  };
}