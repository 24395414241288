<template>
  <div class="button-wrapper">
    <CustomButton
      purpose="reversed"
      small
      @click="handleCancelCreateTarget"
    >
      {{ t('Cancel') }}
    </CustomButton>
    
    <CustomButton
      purpose="action"
      small
      :disabled="!isValid"
      @click="handleCreateTarget(formData)"
    >
      {{ t('Create') }}
    </CustomButton>
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import useTargets from '@/intelligence/composables/useTargets';

defineProps({
  formData: {
    type: Object,
    default: () => ({})
  },
  isValid: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['target-cancelled', 'target-created']);

const { queryTargetData } = useTargets();

async function handleCreateTarget(payload) {
  await queryTargetData({
    type: 'add',
    params: payload
  });
  emit('target-created');
}

function handleCancelCreateTarget () {
  emit('target-cancelled');
}
</script>