<template>
  <div
    class="padded flow"
    :class="mq"
  >
    <CreateTargetsForm
      v-if="!isMobile"
    />
    <CreateTargetsFormSmall
      v-if="isMobile"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';
import CreateTargetsForm from '@/admin/components/Targets/CreateTargetsForm.vue';
import CreateTargetsFormSmall from '@/admin/components/Targets/CreateTargetsFormSmall.vue';

const mq = useMq();
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));
</script>

<style lang="scss" scoped>
  // Overrides
  :deep(.form-group .input-container .svg-container.icon.right,
  .form-group .input-container button.icon.right) {
    z-index: 2;
  }

  :deep(select):not(.expiry select),
  :deep(input[type="text"]),
  :deep(input[type="number"]) {
    font-weight: normal;
  }

  .button-wrapper {
    display: flex;
    gap: var(--spacing-1);
    justify-content: flex-end;
  }

  input[type="checkbox"] {
    box-shadow: none;
  }

  .targets-form {
    padding: var(--spacing-1);
  }

  .padded.mdMinus {
    margin-bottom: var(--spacing-16);
  }
</style>