<template>
  <div class="button-wrapper">
    <CustomButton
      purpose="action"
      small
      @click="toggleFormVisibility"
    >
      {{ t('Create a target') }}
    </CustomButton>
  </div>
  <div
    ref="tableWrapper"
    class="table-wrapper"
  >
    <table>
      <thead>
        <tr>
          <th colspan="2">
            {{ t('Analysis field') }}
          </th>
          <th>
            {{ t('Share with') }}
          </th>
          <th>
            {{ t('Date') }}
          </th>
          <th colspan="2">
            {{ t('Target') }}
          </th>
          <th>
            {{ t('Attained') }}
          </th>
          <th>
            {{ t('Progress') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-if="!isFormVisible"
        >
          <td
            colspan="8"
            class="px-3"
          >
            Ask Vince for design/copy for the 'empty' state when the table has no data.
          </td>
          
        </tr>
        <tr
          v-if="isFormVisible"
        >
          <td colspan="2">
            <div class="column-wrapper">
              <CreateAnalysisFields
                @field-selected="handleFieldSelection"
                @validity-changed="(value) => handleValidityChanged(value, 'analysisFields')"
              />

              <CreateProperty
                :disabled="!isAnalysisFieldSelected"
                :selected-properties="selectedProperties"
                :property-options="propertyOptions"
                @property-selected="handlePropertySelection"
                @validity-changed="(value) => handleValidityChanged(value, 'property')"
              />
            </div>
          </td>
          <td>
            <CreateUsers
              disabled
              :selected-user="selectedUsers"
              @user-selected="handleUserSelection"
            />
          </td>
          <td>
            <CreateData
              :disabled="!isPropertyFieldSelected"
              @interval-selected="handleIntervalSelection"
              @expiry-year-selected="handleExpiryYearSelection"
              @validity-changed="(value) => handleValidityChanged(value, 'date')"
            />
          </td>
          <td colspan="2">
            <div class="column-wrapper">
              <CreateMeasurement
                :disabled="!isDateFieldSelected"
                @measurement-selected="handleMeasurementSelection"
                @validity-changed="(value) => handleValidityChanged(value, 'measurement')"
              />
      
              <CreateTargetAmount
                :disabled="!isMeasurementFieldSelected"
                @target-amount="handleTargetAmount"
                @validity-changed="(value) => handleValidityChanged(value, 'targetAmount')"
              />
            </div>
          </td>
          <td colspan="2">
            <CreateFormActions
              class="text-right"
              :form-data="formData"
              :is-valid="isValid"
              @target-cancelled="handleResetFormData"
              @target-created="handleResetFormData"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import useTargets from '@/intelligence/composables/useTargets';
import CreateAnalysisFields from '@/admin/components/Targets/CreateAnalysisFields.vue';
import CreateProperty from '@/admin/components/Targets/CreateProperty.vue';
import CreateUsers from '@/admin/components/Targets/CreateUsers.vue';
import CreateData from '@/admin/components/Targets/CreateDate.vue';
import CreateMeasurement from '@/admin/components/Targets/CreateMeasurement.vue';
import CreateTargetAmount from '@/admin/components/Targets/CreateTargetAmount.vue';
import CreateFormActions from '@/admin/components/Targets/CreateFormActions.vue';

const store = useStore();
const isFormVisible = ref(false);
const validity = ref({
  analysisFields: false,
  property: false,
  date: false,
  measurement: false,
  targetAmount: false,
});

const {
  formData,
  isAnalysisFieldSelected,
  isPropertyFieldSelected,
  isDateFieldSelected,
  isMeasurementFieldSelected,
  handleFieldSelection,
  handlePropertySelection,
  handleUserSelection,
  handleIntervalSelection,
  handleExpiryYearSelection,
  handleMeasurementSelection,
  handleTargetAmount,
  propertyOptions,
  selectedProperties,
  selectedUsers,
  resetFormData
} = useTargets({ store });

const toggleFormVisibility = () => {
  isFormVisible.value = !isFormVisible.value;
};


const isValid = computed(() => {
  // Check the values of the items in the validity object
  return Object.values(validity.value).every(value => value);
});

function handleValidityChanged(value, component) {
  validity.value[component] = value;
}

function handleResetFormData() {
  isFormVisible.value = false;
  resetFormData(validity);
}
</script>

<style lang="scss" scoped>
  // Overrides
  :deep(.form-group .input-container .svg-container.icon.right,
  .form-group .input-container button.icon.right) {
    z-index: 2;
  }

  :deep(select):not(.expiry select),
  :deep(input[type="text"]),
  :deep(input[type="number"]) {
    font-weight: normal;
    width: auto !important;
  }

  .button-wrapper {
    display: flex;
    gap: var(--spacing-1);
    justify-content: flex-end;
  }

  table {
    width: 100%;
  }

  input[type="checkbox"] {
    box-shadow: none;
  }

  /* Table experiment */
  .table-wrapper {
    /* Only show horizontal scroll bar */
    overflow-x: auto;
    width: 100%;
    position: relative;
    background: linear-gradient(to right, #fff 0%, transparent), linear-gradient(to right, transparent, #fff 100%) 0 100%, linear-gradient(to right, rgba(0, 0, 0, 0.3), transparent), linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent) 0 100%;
    background-repeat: no-repeat;
    background-color: var(--colour-utility-white);
    background-size: 250px 100%, 250px 100%, 20px 100%, 20px 100%;
    background-position: 0 0, 100%, 0 0, right var(--scrollbar-width) bottom 0;
    background-attachment: local, local, scroll, scroll;
    transition: all 0.2s;
  }

  th {
    border: none;
    font-size: 20px;
    padding: var(--spacing-3) var(--spacing-3) var(--spacing-3);
    background: var(--colour-panel-action);
    color: var(--colour-utility-action);
    border-bottom: 1px solid var(--colour-panel-g-16);
    text-decoration: underline;
  }

  tbody {
    background-color: var(--colour-data-puerto-rico-light);
  }

    td {
      border: none;
      font-size: inherit;
    }

    /* Style the last td */
    td:last-child {
      padding-inline-end: var(--spacing-2);
    }

  .column-wrapper {
    display: flex;
    gap: var(--spacing-3);
  }
</style>