<template>
  <div class="date">
    <DropDown
      id="date"
      :is-dropdown-visible="isDropdownVisible"
      :show-footer="showFooter"
      :disabled="props.disabled"
      @trigger-click="toggleDropdown"
      @update:is-dropdown-visible="isDropdownVisible = $event"
    >
      <template #label>
        {{ t('Date') }}
      </template>

      <template #trigger>
        <div
          class="dropdown-trigger"
          :value="selectedDateText"
        >
          {{ selectedDateText || t('Select date') }}
        </div>
      </template>
    
      <template #content>
        <fieldset class="interval">
          <legend>Interval</legend>
          <div class="radio-button__group">
            <span
              v-for="interval in intervals"
              :key="interval.value"
              class="radio-button__item"
              :class="{ checked: selectedRadioValue === interval.value }"
            >
              <CustomRadio
                :id="interval.value"
                :label="t(interval.label)"
                :value="interval.value"
                name="interval"
                :checked="selectedRadioValue === interval.value"
                @on-change="handleIntervalSelection"
              />
            </span>
          </div>
        </fieldset>

        <fieldset class="expiry">
          <CustomSelect
            id="expiryYear"
            v-model="selectedExpiresValue"
            :value="selectedExpiresValue"
            type="select"
            :label="t('Expires')"
            :items="expiresOptions"
            @on-change="handleExpiryYearSelection"
          />
        </fieldset>
      </template>

      <template
        v-if="showFooter"
        #footer
      >
        <CustomButton
          purpose="action"
          small
          @click="applyDateSelection"
        >
          {{ t('Apply') }}
        </CustomButton>
      </template>
    </DropDown>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { t } from '@sales-i/utils';
import { CustomButton, CustomRadio, CustomSelect } from '@sales-i/dsv3';
import DropDown from '@/admin/components/Targets/DropDown.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['interval-selected', 'expiry-year-selected', 'validity-changed']);

const selectedDate = ref({
  interval: '',
  expiry: ''
});
const selectedExpiresValue = ref('');
const isDropdownVisible = ref(false);
const selectedRadioValue = ref('WEEKLY');
const currentYear = new Date().getFullYear();

// Generate the next 10 years for the expiresOptions list
const expiresOptions = ref([
  {
    text: 'Please select',
    value: ''
  },
  ...Array(10)
    .fill(null)
    .map((_, i) => ({
      text: `${currentYear + i}`,
      value: `${currentYear + i}`
    }))
]);

const intervals = [
  {
    value: 'WEEKLY',
    label: t('Week')
  },
  {
    value: 'MONTHLY',
    label: t('Month')
  },
  {
    value: 'QUARTERLY',
    label: t('Quarter')
  },
  {
    value: 'YEARLY',
    label: t('Year')
  }
];

// Mapping of intervals to their text representations
const intervalTextMapping = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly'
};

// Show the footer only if the selected date has changed
const showFooter = computed(() => {
  if (
    selectedExpiresValue.value &&
    selectedRadioValue.value &&
    (selectedExpiresValue.value !== selectedDate.value.expiry || selectedRadioValue.value !== selectedDate.value.interval)
  ) {
    return true;
  }
  return false;
});

// Show the selected date in the input field 
const selectedDateText = computed(() => {
  if (selectedDate.value.interval && selectedDate.value.expiry) {
    const intervalText = intervalTextMapping[selectedDate.value.interval] || selectedDate.value.interval;
    return `${intervalText} until ${selectedDate.value.expiry}`;
  } else {
    return '';
  }
});

const isValid = computed(() => {
  // Check that selectedDate.expiry is a number and selectedDate.interval is not an empty string
  return selectedDate.value.expiry !== '' && selectedDate.value.interval !== '';
});

const handleIntervalSelection = (event) => {
  const selectedInterval = event.value;
  selectedRadioValue.value = selectedInterval;
};

const handleExpiryYearSelection = (event) => {
  const selectedExpiryYear = event;
  selectedExpiresValue.value = selectedExpiryYear;
};

const applyDateSelection = () => {
  selectedDate.value = {
    interval: selectedRadioValue.value,
    expiry: selectedExpiresValue.value
  };
  emit('interval-selected', selectedRadioValue.value);
  emit('expiry-year-selected', selectedExpiresValue.value);
  isDropdownVisible.value = false;
  emit('validity-changed', isValid.value);
  // Adjust focus to the dropdown trigger
  document.getElementById('date').focus();
};
</script>


<style lang="scss" scoped>
:deep(.radio) {
  margin-right: 0;
}

:deep(.radio-button__group .radio .radio-container .check) {
  display: none;
}

:deep(.dropdown-list) {
  min-width: 20rem;
}

.dropdown-trigger {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" ><g stroke="none" stroke-width="1" fill-rule="evenodd"><path d="M18,24 L18,28 L14,28 L14,24 L18,24 Z M18,32 L18,36 L14,36 L14,32 L18,32 Z M22,24 L22,28 L26,28 L26,24 L22,24 Z M26,32 L26,36 L22,36 L22,32 L26,32 Z M30,24 L30,28 L34,28 L34,24 L30,24 Z M34,32 L34,36 L30,36 L30,32 L34,32 Z M38,44 L10,44 C7.79399967,44 6,42.2060013 6,40 L6,12 C6,9.79399967 7.79399967,8 10,8 L14,8 L14,4 L18,4 L18,8 L30,8 L30,4 L34,4 L34,8 L38,8 C40.2060013,8 42,9.79399967 42,12 L42,40 C42,42.2060013 40.2060013,44 38,44 L38,44 Z M38.0002861,16 L38,12 L10,12 L10,16 L38.0002861,16 L38.0002861,16 Z M38.0005722,20 L38.0019989,40 L10,40 L10,20 L38.0005722,20 L38.0005722,20 Z" /></g></svg>');
  background-position: right var(--spacing-1) top 50%;
  background-size: 30px;

  background-color: var(--colour-utility-white);
  border-radius: var(--border-radius-half);
  box-shadow: 0px 0px 0px 1px var(--border-utility-base);
  line-height: 1.75;
  outline: 2px solid transparent;
  padding-block: var(--spacing-1);
  padding-inline: var(--spacing-2) var(--spacing-6);
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

// Padding for the fieldsets
.interval,
.expiry {
  padding: var(--spacing-2);
  margin-block-start: var(--spacing-1);
}

.radio-button__group {
  display: flex;
  gap: var(--spacing-1);
  flex-wrap: wrap;
}

.interval legend {
  padding-block-start: var(--spacing-1);
}

.radio-button__item label {
  border-radius: var(--border-radius-1);
  box-shadow: 0 0 0 2px transparent;
  background-color: var(--colour-panel-action);
  display: block;
  padding: var(--spacing-1) var(--spacing-2);
  color: var(--colour-brand-deluge);
  margin-bottom: 0;
}

.radio-button__item.checked label {
  background-color: var(--colour-utility-black);
  box-shadow: 0 0 0 2px var(--colour-utility-focus);
  color: var(--colour-utility-white);
}

.radio-button__item input:hover ~ label,
.radio-button__item label:hover {
  box-shadow: 0 0 0 2px var(--colour-utility-focus);
}

.radio-button__item label:after {
  border: 2px solid;
  border-color: rgba(0,0,0,0);
  border-radius: var(--border-radius-1);
  bottom: 0;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition:
    bottom .2s ease-in-out,
    border-color .2s ease-in-out,
    left .2s ease-in-out,
    right .2s ease-in-out,
    top .2s ease-in-out;
}

</style>