<template>
  <label
    for="fieldSelect"
    :class="{ 'visually-hidden': !isMobile }"
  >
    {{ t('Select analysis field') }}
  </label>
  <div class="analysis-field">
    <select
      id="fieldSelect" 
      :value="selectedAnalysisFieldString"
      @input="handleInput"
      @blur="handleFieldSelection"
      @focus="storePreviousField"
    >
      <option 
        value="" 
        selected
      >
        Select analysis field
      </option>

      <optgroup 
        v-for="category in fieldCategoriesData"
        :key="category.name"
        :label="category.name"
      >
        <option
          v-for="field in categoryFieldNames[category.name]"
          :key="field.id"
          :value="JSON.stringify({
            entity: category.name.toLowerCase(),
            fieldId: field.id,
            fieldName: field.name
          })"
        >
          {{ field.name }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import useAdminUsers from '@/admin/composables/useAdminUsers';
import useTargets from '@/intelligence/composables/useTargets';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';

const store = useStore();

const { getFields } = useAdminUsers({ store });
const { fetchFieldsValue } = useTargets();

const emit = defineEmits(['field-selected', 'validity-changed']);

const mq = useMq();
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));
const selectedAnalysisField = ref('');
const previousAnalysisField = ref('');
const fieldCategoriesData = ref([]);
const fieldNamesMap = ref({
  customer: [],
  product: [],
  sales: []
});

const handleInput = (event) => {
  const selectedOption = event.target.selectedOptions[0];
  const optionValue = selectedOption.value;
  
  try {
    const value = optionValue ? JSON.parse(optionValue) : '';
    selectedAnalysisField.value = value;
  } catch (e) {
    selectedAnalysisField.value = '';
  }
};

const selectedAnalysisFieldString = () => {
  return selectedAnalysisField.value ? JSON.stringify(selectedAnalysisField.value) : '';
};

const categoryFieldNames = computed(() => {
  const result = {};
  fieldCategoriesData.value.forEach(category => {
    const entityType = category.name.toLowerCase();
    result[category.name] = fieldNamesMap.value[entityType] || [];
  });
  return result;
});

const isValid = computed(() => {
  return selectedAnalysisField.value !== '';
});

onMounted(async () => {
  await fetchFieldCategories();
  await Promise.all([
    fetchFieldNames('customer'),
    fetchFieldNames('product'),
    fetchFieldNames('sales')
  ]);
});

const storePreviousField = () => {
  previousAnalysisField.value = selectedAnalysisField.value;
};

const handleFieldSelection = async () => {
  emit('field-selected', selectedAnalysisField.value);

  if (selectedAnalysisField.value) {
    const { entity, fieldId } = selectedAnalysisField.value;
    // Reset tempSelectedProperty when analysis field changes
    await fetchFieldsValue(entity, fieldId);
  }
  emit('validity-changed', isValid.value);
};

async function fetchFieldCategories() {
  await getFields({
    type: 'analysis',
  });

  fieldCategoriesData.value = store.state.admin.users.fields.data.analysis || [];
}

async function fetchFieldNames(entity) {
  await getFields({
    type: 'analysis',
    entity: entity,
  });

  fieldNamesMap.value[entity] = store.state.admin.users.fields.data[entity] || [];
}
</script>

<style lang="scss" scoped>
  .analysis-field {
    position: relative;
  }

  select {
    background-image: url( 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="%235c5c5c" d="M23.9998169,32.4246826 L36.0206337,20.4038696 L33.1922054,17.5754395 L23.9998188,26.767828 L14.8074324,17.5754395 L11.9790039,20.4038663 L23.9998169,32.4246826 Z" /></svg>');
    background-position: right var(--spacing-1) top 50%;
    background-size: 30px;
  }
</style>